<template>
  <div class="algorithm">
      <div class="algorithm-header">
            <div>
              <div class="title">核心算法技术</div>
              <div class="info">深入各应用场景，倾听行业需求，不断迭代， 落实高实用  高精准的多场景算法</div>
            </div>
            <div class="tools-bottom">
              <div class="tools-item"
                   v-for="(item,index) in typeList"
                   :key="index"
                   :class="activeId==item.id?'active':''"
                   @click="goDetail(item)"
              >
                {{item.productName}}
              </div>

            </div>
      </div>
      <div class="algorithm-content">
              <div class="title">{{productName}}</div>
              <div class="product-box">
                  <div class="product-item"
                       v-for="(item,index) in list"
                       :key="index"
                  >
                    <div class="img-bg"
                         :style="{backgroundImage:'url('+item.imgUrl+')'}"
                    >
                    </div>
                    <div class="title">{{item.title}}</div>
                    <div class="info">{{item.description}}</div>
                    <div class="keyword">关键词：{{item.keyword}}</div>


                  </div>
              </div>
      </div>
  </div>

</template>

<script>
export default {
  name: "algorithm",
  data(){
    return {
      list:[],
      typeList:[],
      activeId:this.$route.query.id,
      productName:'基础算法检测'
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
    this.getList();
  },
  methods:{
    getDetail(){
      this.$get('/website/product/algorithm/list?productId='+this.$route.query.id).then((res)=>{
        this.list=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    getList(){
      this.$get('/website/product/algorithm/getAlgorithmType').then((res)=>{
          this.typeList=res.data
      })
    },
    goDetail(item){
      this.activeId=item.id;
      this.productName=item.productName
      if(item.styleType==5){
        this.$router.push({
          name:'algorithm',
          query:{
            id:item.id
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.algorithm{
  .algorithm-header{
    width: 100%;
    height: 507px;
    background-image: url("../../assets/algorithm-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    position: relative;
    .title{
      font-size: 38px;
    }
    .info{
      margin-top: 20px;
      font-size: 24px;
    }
    .tools-bottom{
      position: absolute;
      bottom: 2px;
      display: flex;
      justify-content: center;
      .tools-item{
        height: 30px;
        line-height: 30px;
        margin-right: 30px;
        border-bottom: 3px solid transparent;
        padding: 0 5px;
        cursor: pointer;
      }
      .tools-item.active{
        border-bottom: 3px solid #F2F2F2;
      }
    }
  }
  .algorithm-content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
    >.title{
      font-size: 38px;
      text-align: center;
    }
    .product-box{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .product-item{
        width: 340px;
        margin-right: 30px;
        margin-top: 80px;
        .title{
          font-size: 20px;
          font-weight: bold;
          padding: 0 20px;
          margin-top: 20px;
        }
        .info{
          font-size: 14px;
          color: #797979;
          padding: 0 20px;
          margin-top: 10px;
          height: 60px;
        }
        .keyword{
          font-size: 14px;
          color: #797979;
          margin-top: 50px;
          padding: 0 20px;
        }
        .img-bg{
          width: 340px;
          height: 240px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all 0.5s;
        }
     .img-bg:hover{
        background-size: 120% 120%;
      }
      }
      .product-item:nth-child(3n){
        margin-right: 0;
      }
    }
  }
}
</style>
